// export const baseUrl = "http://localhost:8080";
export const baseUrl = "https://api.theplanner.co.kr";
export const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken"),
};

export const kakao_pfId = "";
export const kakao_templateId_delivery = "";
export const kakao_templateId_complete = "";

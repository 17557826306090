import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchUserList(params) {
  return axios.get(baseUrl + `/api/admin/user/list`, { params, headers });
}

export function fetchUserDetails(id) {
  return axios.get(baseUrl + `/api/admin/user/${id}`, { headers });
}

export function upadateUserDetails(data) {
  return axios.put(baseUrl + `/api/admin/user`, data, { headers });
}

export function removeUser(id) {
  return axios.delete(baseUrl + `/api/admin/user/${id}`, { headers });
}

export function fetchDashCount() {
  return axios.get(baseUrl + `/api/admin/dashboard`, { headers });
}
export function createUserMemo(data) {
  return axios.post(baseUrl + `/api/admin/memo`, data, { headers });
}
export function fetchUserMemoList(params) {
  return axios.get(baseUrl + `/api/admin/memo/list`, { params, headers });
}
export function removeMemo(id) {
  return axios.delete(baseUrl + `/api/admin/memo/${id}`, { headers });
}
export function fetchUserLikesList(params) {
  return axios.get(baseUrl + `/api/admin/user/likes`, { params, headers });
}
// 웨딩장소
export function createWeddingPlace(data) {
  return axios.post(baseUrl + `/api/admin/weddingPlace`, data, { headers });
}
export function fetchWeddingPlaceList(params) {
  return axios.get(baseUrl + `/api/admin/weddingPlace/list`, {
    params,
    headers,
  });
}
export function fetchWeddingPlaceDetail(id) {
  return axios.get(baseUrl + `/api/admin/weddingPlace/${id}`, { headers });
}
export function upadateWeddingPlace(data) {
  return axios.put(baseUrl + `/api/admin/weddingPlace`, data, { headers });
}
export function removeWeddingPlace(id) {
  return axios.delete(baseUrl + `/api/admin/weddingPlace/${id}`, { headers });
}
// 컬렉션
export function createCollection(data) {
  return axios.post(baseUrl + `/api/admin/collection`, data, { headers });
}
export function fetchCollectionList(params) {
  return axios.get(baseUrl + `/api/admin/collection/list`, { params, headers });
}
export function fetchCollectionDetail(id) {
  return axios.get(baseUrl + `/api/admin/collection/${id}`, { headers });
}
export function upadateCollection(data) {
  return axios.put(baseUrl + `/api/admin/collection`, data, { headers });
}
export function removeCollection(id) {
  return axios.delete(baseUrl + `/api/admin/collection/${id}`, { headers });
}
// 스토어
export function createStore(data) {
  return axios.post(baseUrl + `/api/admin/store`, data, { headers });
}
export function fetchStoreList(params) {
  return axios.get(baseUrl + `/api/admin/store/list`, { params, headers });
}
export function fetchStoreDetail(id) {
  return axios.get(baseUrl + `/api/admin/store/${id}`, { headers });
}
export function upadateStore(data) {
  return axios.put(baseUrl + `/api/admin/store`, data, { headers });
}
export function removeStore(id) {
  return axios.delete(baseUrl + `/api/admin/store/${id}`, { headers });
}
// 플래너
export function createPlanner(data) {
  return axios.post(baseUrl + `/api/admin/planner`, data, { headers });
}
export function fetchPlannerList(params) {
  return axios.get(baseUrl + `/api/admin/planner/list`, { params, headers });
}
export function fetchPlannerDetail(id) {
  return axios.get(baseUrl + `/api/admin/planner/${id}`, { headers });
}
export function upadatePlanner(data) {
  return axios.put(baseUrl + `/api/admin/planner`, data, { headers });
}
export function removePlanner(id) {
  return axios.delete(baseUrl + `/api/admin/planner/${id}`, { headers });
}
// 포트폴리오
export function createPortfolio(data) {
  return axios.post(baseUrl + `/api/admin/portfolio`, data, { headers });
}
export function fetchPortfolioList(params) {
  return axios.get(baseUrl + `/api/admin/portfolio/list`, { params, headers });
}
export function fetchPortfolioDetail(id) {
  return axios.get(baseUrl + `/api/admin/portfolio/${id}`, { headers });
}
export function upadatePortfolio(data) {
  return axios.put(baseUrl + `/api/admin/portfolio`, data, { headers });
}
export function removePortfolio(id) {
  return axios.delete(baseUrl + `/api/admin/portfolio/${id}`, { headers });
}
// 상담내역
export function fetchConsultList(params) {
  return axios.get(baseUrl + `/api/admin/consult/list`, { params, headers });
}
export function fetchConsultDetail(id) {
  return axios.get(baseUrl + `/api/admin/consult/${id}`, { headers });
}
export function upadateConsultReply(data) {
  return axios.put(baseUrl + `/api/admin/consult/reply`, data, { headers });
}
export function removeConsult(id) {
  return axios.delete(baseUrl + `/api/admin/consult/${id}`, { headers });
}
// QnA
export function fetchQnAList(params) {
  return axios.get(baseUrl + `/api/admin/qna/list`, { params, headers });
}
export function fetchQnADetail(id) {
  return axios.get(baseUrl + `/api/admin/qna/${id}`, { headers });
}
export function upadateQnAReply(data) {
  return axios.put(baseUrl + `/api/admin/qna`, data, { headers });
}
export function removeQnA(id) {
  return axios.delete(baseUrl + `/api/admin/qna/${id}`, { headers });
}

// 이벤트

export function fetchEventList(params) {
  return axios.get(baseUrl + `/api/admin/event/list`, { params, headers });
}
export function fetchEventDetail(id) {
  return axios.get(baseUrl + `/api/admin/event/${id}`, { headers });
}
export function createEvent(data) {
  return axios.post(baseUrl + `/api/admin/event`, data, { headers });
}

export function upadateEvent(data) {
  return axios.put(baseUrl + `/api/admin/event`, data, { headers });
}
export function removeEvent(id) {
  return axios.delete(baseUrl + `/api/admin/event/${id}`, { headers });
}
// 배너관리
export function createBanner(data) {
  return axios.post(baseUrl + `/api/admin/banner`, data, { headers });
}
export function fetchBanner() {
  return axios.get(baseUrl + `/api/admin/banner`, { headers });
}
export function updateBanner(data) {
  return axios.put(baseUrl + `/api/admin/banner`, data, { headers });
}
export function deleteBanner(params) {
  return axios.delete(baseUrl + `/api/admin/banner`, { params, headers });
}
// 리뷰관리
export function fetchReview(params) {
  return axios.get(baseUrl + `/api/admin/review/list`, { params, headers });
}
export function updateReview(data) {
  return axios.put(baseUrl + `/api/admin/review`, data, { headers });
}
export function fetchReviewDetail(id) {
  return axios.get(baseUrl + `/api/admin/review/${id}`, { headers });
}
export function deleteReview(id) {
  return axios.delete(baseUrl + `/api/admin/review/${id}`, { headers });
}
// 아카이브 - 게시글
export function createArchivePost(data) {
  return axios.post(baseUrl + `/api/admin/archive`, data, { headers });
}
export function fetchArchivePostList(params) {
  return axios.get(baseUrl + `/api/archive/list`, {
    params,
    headers,
  });
}
export function fetchArchivePostDetail(id) {
  return axios.get(baseUrl + `/api/admin/archive/${id}`, { headers });
}
export function upadateArchivePost(data) {
  return axios.put(baseUrl + `/api/admin/archive`, data, { headers });
}
export function removeArchivePost(id) {
  return axios.delete(baseUrl + `/api/admin/archive/${id}`, { headers });
}

// 스케쥴 관리
export function fetchScheduleList(params) {
  return axios.get(baseUrl + `/api/admin/diary/list`, { params, headers });
}
export function createSchedule(data) {
  return axios.post(baseUrl + `/api/admin/diary`, data, { headers });
}
export function fetchScheduleDetail(id) {
  return axios.get(baseUrl + `/api/admin/diary/${id}`, { headers });
}
export function updateSchedule(data) {
  return axios.put(baseUrl + `/api/admin/diary`, data, { headers });
}
export function removeSchedule(id) {
  return axios.delete(baseUrl + `/api/admin/diary/${id}`, { headers });
}
export function findPlannerByUserId(params) {
  return axios.get(baseUrl + `/api/admin/find/planner`, { params, headers });
}

// 회원정보 - 계약관리
export function createAgreement(data) {
  return axios.post(baseUrl + `/api/admin/agreement`, data, { headers });
}
export function fetchAgreementDetail(id) {
  return axios.get(baseUrl + `/api/admin/agreement/${id}`, { headers });
}
export function updateAgreement(data) {
  return axios.put(baseUrl + `/api/admin/agreement`, data, { headers });
}
export function updateVendorMemo(data) {
  return axios.put(baseUrl + `/api/admin/vendor/memo`, data, { headers });
}
export function fetchVendorMemoDetail(id) {
  return axios.get(baseUrl + `/api/admin/vendor/memo/${id}`, { headers });
}
export function removeAgreement(id) {
  return axios.delete(baseUrl + `/api/admin/agreement/${id}`, { headers });
}

export function createWithdraw(data) {
  return axios.post(baseUrl + "/api/admin/withdraw", data, { headers });
}

//웨딩투어
export function fetchTourList(params) {
  return axios.get(baseUrl + `/api/admin/tour/list`, { params, headers });
}

export function createTour(data) {
  return axios.post(baseUrl + `/api/admin/tour`, data, { headers });
}

export function fetchTourDetail(id) {
  return axios.get(baseUrl + `/api/admin/tour/${id}`, { headers });
}

export function updateTour(data) {
  return axios.put(baseUrl + `/api/admin/tour`, data, { headers });
}

export function updateTourStamp(data) {
  return axios.put(baseUrl + `/api/admin/tour/stamp`, data, { headers });
}

export function removeTour(id) {
  return axios.delete(baseUrl + `/api/admin/tour/${id}`, { headers });
}

// 투어 상품 등록

export function fetchGiftList(params) {
  return axios.get(baseUrl + `/api/admin/gift/list`, { params, headers });
}
export function fetchGiftDetail(id) {
  return axios.get(baseUrl + `/api/admin/gift/${id}`, { headers });
}
export function createGift(data) {
  return axios.post(baseUrl + `/api/admin/gift`, data, { headers });
}
export function updateGift(data) {
  return axios.put(baseUrl + `/api/admin/gift`, data, { headers });
}
export function removeGift(id) {
  return axios.delete(baseUrl + `/api/admin/gift/${id}`, { headers });
}
fetchOrderList;

// 투어 상품 주문 내역

export function fetchOrderList(params) {
  return axios.get(baseUrl + `/api/admin/order/list`, { params, headers });
}
export function fetchOrderDetail(id) {
  return axios.get(baseUrl + `/api/admin/order/${id}`, { headers });
}

export function updateOrder(data) {
  return axios.put(baseUrl + `/api/admin/order`, data, { headers });
}
export function removeOrder(id) {
  return axios.delete(baseUrl + `/api/admin/order/${id}`, { headers });
}

import { fetchProfile } from "@/api/index";
// import { fetchTourDetailByUserId } from "@/api/index";
import {
  fetchConsultList,
  fetchQnAList,
  findPlannerByUserId,
} from "@/api/admin";

const actions = {
  /**
   * LOGIN
   */
  LOGIN({ commit }, data) {
    commit("LOGIN", data.accesstoken);
    commit("SET_USER_INFO", data.userInfo);
  },
  /*
   * LOGOUT
   */
  LOGOUT({ commit }) {
    commit("LOGOUT");
    window.location.href = "/";
  },

  /**
   * fetch User 정보
   */
  getAccountInfo({ commit, dispatch }) {
    fetchProfile().then(async (res) => {
      if (res.data.status == 200) {
        let data = {
          email: res.data.data.email,
          _id: res.data.data._id,
          isAdmin: res.data.data.isAdmin,
          mobileNo: res.data.data.mobileNo,
          username: res.data.data.username,
          userType: res.data.data.userType,
          status: res.data.data.status,
          adminType: res.data.data.adminType,
        };
        if (
          res.data.data.adminType == "PLANNER" ||
          res.data.data.adminType == "ADVISOR"
        ) {
          await findPlannerByUserId().then((res) => {
            if (res.data.status == 200) {
              data.plannerId = res.data.data ? res.data.data._id : "";
            }
          });
        }
        commit("SET_USER_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },

  async getNewRequestList({ commit }) {
    let params = {
      isReply: false,
    };
    let adminType = localStorage.getItem("adminType");
    if (adminType == "PLANNER") {
      await findPlannerByUserId().then((res) => {
        if (res.data.status == 200) {
          if (res.data.data != null) {
            params.plannerId = res.data.data._id;
          }
        }
      });
    }
    fetchConsultList(params).then((res) => {
      let count = res.data.total;
      commit("SET_CONSULT_COUNT", count);
    });
    fetchQnAList(params).then((res) => {
      let data = {
        qna: res.data.data.filter((item) => item.category != "제휴문의").length,
        partnership: res.data.data.filter((item) => item.category == "제휴문의")
          .length,
      };
      commit("SET_QNA_COUNT", data);
    });
  },

  SET_NAVBAR({ commit }, data) {
    commit("SET_NAVBAR", data);
  },
  SET_NAVBAR_VISIBLE({ commit }, data) {
    commit("SET_NAVBAR_VISIBLE", data);
  },
  SET_FOOTER({ commit }, data) {
    commit("SET_FOOTER", data);
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
};

export default actions;

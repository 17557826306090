import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// const requireAuth = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken == null || accesstoken == undefined) {
//     alert("로그인 후 사용가능 합니다.");
//     next("/");
//   } else {
//     next();
//   }
//   next();
// };

// NavigationDuplicated Error 해결 코드
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const requireAdmin = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken) {
    next();
  } else {
    next("/");
  }
};

// PLANNER & ADVISOR & SUPER
const requirePAS = () => (to, from, next) => {
  let adminType = localStorage.getItem("adminType");
  if (
    adminType == "PLANNER" ||
    adminType == "ADVISOR" ||
    adminType == "SUPER"
  ) {
    next();
  } else {
    next("/");
  }
};
// MANAGER & ADVISOR & SUPER
const requireMAS = () => (to, from, next) => {
  let adminType = localStorage.getItem("adminType");
  if (
    adminType == "MANAGER" ||
    adminType == "ADVISOR" ||
    adminType == "SUPER"
  ) {
    next();
  } else {
    next("/");
  }
};

//  SUPER
const requireSuper = () => (to, from, next) => {
  let adminType = localStorage.getItem("adminType");
  if (adminType == "SUPER") {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/account/Login.vue"),
  },

  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/Container.vue"),

    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/account/Login.vue"),
      },
    ],
  },
  {
    path: "/error404",
    name: "error404",
    component: () => import("../views/pop/Error404.vue"),
  },
  {
    path: "/error503",
    name: "error503",
    component: () => import("../views/pop/Error503.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Container.vue"),
    beforeEnter: requireAdmin(),

    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/admin/Dashboard.vue"),
        beforeEnter: requireSuper(),
      },
      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/Users.vue"),
        beforeEnter: requirePAS(),
      },

      {
        path: "userDetail",
        name: "userDetail",
        component: () => import("../views/admin/UserDetail.vue"),
        beforeEnter: requirePAS(),
      },

      {
        path: "placemanage",
        name: "placemanage",
        component: () => import("../views/admin/PlaceManage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "placemanage/register",
        name: "placeRegister",
        component: () => import("../views/admin/PlaceRegister.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "collectionmanage",
        name: "collectionmanage",
        component: () => import("../views/admin/CollectionManage.vue"),
        beforeEnter: requireMAS(),
      },
      // {
      //   path: "collectionRequestManage",
      //   name: "collectionRequestManage",
      //   component: () => import("../views/admin/CollectionRequestManage.vue"),
      // },
      {
        path: "collectionmanage/register",
        name: "collectionregister",
        component: () => import("../views/admin/CollectionRegister.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "storemanage",
        name: "storemanage",
        component: () => import("../views/admin/StoreManage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "storemanage/register",
        name: "storeregister",
        component: () => import("../views/admin/StoreRegister.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "eventmanage",
        name: "eventmanage",
        component: () => import("../views/admin/EventManage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "eventmanage/register",
        name: "eventregister",
        component: () => import("../views/admin/EventRegister.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "reviewmanage",
        name: "reviewmanage",
        component: () => import("../views/admin/ReviewManage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "reviewmanage/detail",
        name: "reviewdetail",
        component: () => import("../views/admin/ReviewDetail.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "plannermanage",
        name: "plannermanage",
        component: () => import("../views/admin/PlannerManage.vue"),
        beforeEnter: requireSuper(),
      },
      {
        path: "plannermanage/register",
        name: "plannerregister",
        component: () => import("../views/admin/PlannerRegister.vue"),
      },
      {
        path: "schedulemanage",
        name: "schedulemanage",
        component: () => import("../views/admin/ScheduleManage.vue"),
        beforeEnter: requirePAS(),
      },
      {
        path: "schedulemanage/register",
        name: "scheduleregister",
        component: () => import("../views/admin/ScheduleRegister.vue"),
        beforeEnter: requirePAS(),
      },
      {
        path: "archivemanage",
        name: "archivemanage",
        component: () => import("../views/admin/ArchiveManage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "archivemanage/register",
        name: "archiveRegister",
        component: () => import("../views/admin/ArchiveRegister.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "qnareply",
        name: "qnareply",
        component: () => import("../views/admin/QnaReply.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "qnamanage",
        name: "qnamanage",
        component: () => import("../views/admin/QnaManage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "partnershipManage",
        name: "partnershipManage",
        component: () => import("../views/admin/PartnershipManage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "partnershipDetail",
        name: "partnershipDetail",
        component: () => import("../views/admin/PartnershipDetail.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "consultreply",
        name: "consultreply",
        component: () => import("../views/admin/ConsultReply.vue"),
      },
      {
        path: "consultmanage",
        name: "consultmanage",
        component: () => import("../views/admin/ConsultManage.vue"),
      },
      {
        path: "bannermanage",
        name: "bannermanage",
        component: () => import("../views/admin/Banner.vue"),
        beforeEnter: requireMAS(),
      },

      {
        path: "popupmanage",
        name: "popupmanage",
        component: () => import("../views/admin/Popup.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "tour/manage",
        name: "tourmanage",
        component: () => import("../views/admin/tour/Manage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "tour/register",
        name: "tourRegister",
        component: () => import("../views/admin/tour/Register.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "tour/stamp",
        name: "stamp",
        component: () => import("../views/admin/tour/Stamp.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "gift/manage",
        name: "giftmanage",
        component: () => import("../views/admin/gift/Manage.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "gift/register",
        name: "giftRegister",
        component: () => import("../views/admin/gift/Register.vue"),
        beforeEnter: requireMAS(),
      },
      {
        path: "order/manage",
        name: "ordermanage",
        component: () => import("../views/admin/order/Manage.vue"),
        beforeEnter: requireMAS(),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { x: 0, y: 0 };
      // return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

const state = {
  id: null,
  _id: null,
  email: "", //이메일
  status: "",
  mobileNo: "",
  username: "",
  userType: "",
  avatar: "",
  qnaCount: null,
  consultCount: null,
  patnershipCount: null,
  navbarState: "0",
  isLogin: false,
  isAdmin: false,
};
export default state;
